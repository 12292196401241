import { StateFrom, ActionTypes } from "xstate";
import onpageWidgetMachine from "../widget/statecharts/onpageWidget";
import { OnpageWidgetEvent } from "../widget/statecharts/onpageWidget/types";
import { environment } from "../environment";

export function sendToGtm(event: any, dataLayerName = "dataLayer") {
  /* @ts-ignore-line */
  window[dataLayerName] = window[dataLayerName] || [];
  /* @ts-ignore-line */
  window[dataLayerName].push(event);
}

export function initializeGtm(
  containerId: string,
  dataLayerName = "dataLayer",
  environment?: Record<string, string>
) {
  return new Promise((resolve, reject) => {
    if (!containerId) return reject("gtmContainerId undefined");

    const url = new URL("https://www.googletagmanager.com/gtm.js");
    url.search = new URLSearchParams(environment).toString();
    url.searchParams.set("id", containerId);
    url.searchParams.set("l", dataLayerName);

    const gtmScriptEl = window.document.createElement("script");
    gtmScriptEl.async = true;
    gtmScriptEl.onload = resolve;
    gtmScriptEl.onerror = reject;
    gtmScriptEl.src = url.toString();

    const firstScriptEl = window.document.getElementsByTagName("script")[0];
    firstScriptEl.parentNode?.insertBefore(gtmScriptEl, firstScriptEl);
  });
}

// This function sanitizes XState events before we send them to GTM in order
// to avoid sending sentitive user data like access tokens etc.
export function sanitizeXStateEventForGtm(event: Record<string, any>) {
  // Whitelist of allowed top-level properties and data properties
  // Note that the _contents_ of allowed properties are not filtered
  // (no deep filtering)
  const whitelist = [
    "tab",
    "offering",
    "itemAdded",
    "paymentCompleted",
    "userAccessStatus",
    "screenHint",
    "isNewUser",
  ];
  return whitelist.reduce((sanitizedEvent, key) => {
    if (event[key] !== undefined) {
      sanitizedEvent[key] = event[key];
    }
    if (event.data?.[key] !== undefined) {
      sanitizedEvent[key] = event.data?.[key];
    }
    return sanitizedEvent;
  }, {} as Record<string, any>);
}

// Returns a function that sends events to GTM
const getSendEvent =
  (state: StateFrom<typeof onpageWidgetMachine>) =>
  (type: string, data?: Record<string, string | number | null | undefined>) => {
    sendToGtm(
      {
        event: type,
        packageVersion: environment.packageVersion,
        env: environment.env,
        state: state.toStrings().pop(),
        checkoutType: state.context.checkoutType,
        isAuthenticated: state.context.userAuthorized,
        userAccessStatus: state.context.userAccessStatus,
        ...data,
      },
      state.context.gtmDataLayerName
    );
  };

//Send GTM events for each transition of the onpageWidget
export function trackTransition(
  state: StateFrom<typeof onpageWidgetMachine>,
  event: OnpageWidgetEvent
) {
  const currentState = state.toStrings().pop();
  const sendEvent = getSendEvent(state);

  // Skip Update events
  if (event.type === ActionTypes.Update) {
    return;
  }

  if (event.type === ActionTypes.Init) {
    //Sends gtm start event
    sendToGtm(
      {
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      },
      state.context.gtmDataLayerName
    );
  }

  if (event.type === "DONE_TAB_ADD" && event.data.itemAdded) {
    sendEvent("cow.core.itemAddSuccess", {
      paymentModel: event.data.offering.paymentModel,
      salesModel: event.data.offering.salesModel,
      offeringDescription: event.data.offering.description,
      offeringCurrency: event.data.offering.price.currency,
      offeringPrice: event.data.offering.price.amount,
      tabTotal: event.data.tab.total,
      tabCurrency: event.data.tab.currency,
    });
  }

  if (event.type === "AUTH_WINDOW_DONE" && event.isNewUser) {
    sendEvent("cow.core.userSignUpSuccess");
  }

  if (event.type === "AUTH_WINDOW_DONE" && !event.isNewUser) {
    sendEvent("cow.core.userSignInSuccess");
  }

  if (event.type === "AUTH_WINDOW_FAILED" && event.screenHint === "register") {
    sendEvent("cow.core.userSignUpFail", {
      loginAction: event.loginAction,
    });
  }

  if (event.type === "AUTH_WINDOW_FAILED" && event.screenHint !== "register") {
    sendEvent("cow.core.userSignInFail", {
      loginAction: event.loginAction,
    });
  }

  if (event.type === "DONE_PAYMENT" && event.data.paymentCompleted) {
    sendEvent("cow.core.tabPaymentSuccess", {
      tabTotal: event.data.tab?.total,
      tabCurrency: event.data.tab?.currency,
    });
    return;
  }

  //Send cow.* events to GTM for each widget event
  sendEvent(`cow.${event.type}`, sanitizeXStateEventForGtm(event));

  //Send cow.view.* events to GTM mimicing a page view
  //for each state transition of the widget
  sendEvent(`cow.view.${currentState}`);
}
