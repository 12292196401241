import React, { useCallback, useEffect, useState } from "react";
import Price from "../atoms/Price";
import {
  OfferingContainer,
  OfferingInput,
  OfferingText,
  OfferingsContainer,
  OfferingTimeDelta,
  SingleOfferingContainer,
  SingleOffering,
  SubscriptionToggleWrapper,
  PutItOnMyTabButton,
  TimePassCopyWrapper,
} from "./styled";
import { CurrencyResponse, ItemOffering } from "../../../tapper-browser-client";
import { formattedPrice, Trans, useTranslation } from "../../util/i18n";
import Toggle from "../atoms/Toggle";
import { OfferingBillingInterval } from "./OfferingBillingInterval";
import { PaymentModel } from "@getsupertab/tapper-sdk";
import { useTheme } from "@emotion/react";

interface OfferingsProps {
  currency: CurrencyResponse;
  loading?: boolean;
  offerings: ItemOffering[];
  isSubscriptions: boolean;
  onShowSubscriptionDetails?: (
    isSubscriptionEnabled: boolean,
    discount: number
  ) => void;
  onPickOffering?: (offering: ItemOffering) => void;
  canSelectOfferings?: boolean;
  singleOfferingSubtitle?: string;
  merchant: { name: string };
  isLoggedIn: boolean;
}

export default function Offerings({
  currency,
  loading = false,
  offerings,
  isSubscriptions,
  onPickOffering,
  onShowSubscriptionDetails,
  canSelectOfferings = true,
  singleOfferingSubtitle = "",
  merchant,
  isLoggedIn,
}: OfferingsProps) {
  const { t, i18n } = useTranslation();
  const localeCode = i18n.language;
  const [selectedOfferingIndex, setSelectedOfferingIndex] = useState(0);
  const [offeringsList, setOfferingList] = useState<ItemOffering[] | []>([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const selectedOffering = offeringsList[selectedOfferingIndex];
  console.log("selectedOffering", selectedOffering);
  const showSubscriptionsToggle =
    (!!selectedOffering?.subscriptionOfferingId &&
      !!selectedOffering?.connectedSubscriptionOffering?.isActive) ||
    !!selectedOffering?.recurringDetails;
  const theme = useTheme();

  const subscriptionItem = offerings.find((offering) => {
    return (
      (offering.salesModel as unknown) === "subscription" ||
      offering.paymentModel === PaymentModel.NowSubscription
    );
  });

  const listOfOfferings = useCallback(() => {
    const initialList = offerings.filter((offering) => {
      return offering.paymentModel === "pay_later";
    });
    if (
      selectedOffering?.subscriptionOfferingId !== undefined &&
      isSubscriptions &&
      subscriptionItem
    ) {
      initialList.splice(selectedOfferingIndex, 1, subscriptionItem);
    }

    return initialList;
  }, [isSubscriptions]);

  useEffect(() => {
    setOfferingList(listOfOfferings());
  }, [isSubscriptions]);

  useEffect(() => {
    const offeringWithSubscriptionPrice: number | undefined = offerings.find(
      (offering) => {
        return (
          !!offering.subscriptionOfferingId &&
          offering.paymentModel === "pay_later"
        );
      }
    )?.price.amount;

    if (subscriptionItem && offeringWithSubscriptionPrice) {
      const percentage =
        (subscriptionItem.price.amount / offeringWithSubscriptionPrice) * 100;

      setDiscountPercentage(Math.round(100 - percentage));
    }
  }, [offerings, subscriptionItem, showSubscriptionsToggle]);

  return (
    <>
      <OfferingsContainer
        data-testid="offerings-list"
        data-loading={loading}
        aria-label="Choose an offering"
        role="radiogroup"
      >
        {canSelectOfferings ? (
          offeringsList.length &&
          offeringsList.map((offering: any, index: any) => (
            <OfferingContainer
              key={offering.id}
              data-testid={`btn-offering-${index}`}
              htmlFor={offering.id}
            >
              <OfferingInput
                aria-label={`${formattedPrice({
                  amount: offering.price.amount,
                  localeCode,
                  currency,
                })} for ${t("formattedTimeDelta", {
                  value: offering.timePassDetails?.validTimedelta,
                })}`}
                checked={selectedOffering === offering}
                id={offering.id}
                name="offering"
                onChange={() => setSelectedOfferingIndex(index)}
                role="radio"
                type="radio"
              />
              <OfferingText theme={theme}>
                {loading ? (
                  <span>&#183;&#183;&#183;</span>
                ) : (
                  <Price
                    weight={
                      offering.timePassDetails?.validTimedelta
                        ? "bold"
                        : "normal"
                    }
                    currency={currency}
                    amount={offering.price.amount}
                  />
                )}
                <OfferingTimeDelta
                  data-checked={selectedOffering === offering}
                  theme={theme}
                >
                  <OfferingBillingInterval
                    offering={offering}
                    loading={loading}
                  />
                </OfferingTimeDelta>
              </OfferingText>
            </OfferingContainer>
          ))
        ) : (
          <SingleOfferingContainer>
            {singleOfferingSubtitle.length ? (
              singleOfferingSubtitle
            ) : (
              <Trans i18nKey="singleOfferingSubtitle" values={{ merchant }} />
            )}
            <SingleOffering>
              <Trans
                i18nKey="singleOfferingText"
                values={{
                  price: formattedPrice({
                    amount: selectedOffering?.price.amount,
                    currency,
                    localeCode,
                  }),
                  timeDelta: t("formattedTimeDelta", {
                    value: selectedOffering?.timePassDetails?.validTimedelta,
                  }),
                }}
              />
            </SingleOffering>
          </SingleOfferingContainer>
        )}
      </OfferingsContainer>

      {showSubscriptionsToggle ? (
        <SubscriptionToggleWrapper data-testid="subscription-toggle-wrapper">
          <Toggle
            testId="subscriptions-toggle"
            checked={isSubscriptions}
            onToggle={() =>
              onShowSubscriptionDetails?.(isSubscriptions, discountPercentage)
            }
          />
          {isSubscriptions ? (
            <Trans
              i18nKey="subscriptions.toggleEnabled"
              values={{
                discount: discountPercentage,
              }}
            />
          ) : (
            <Trans
              i18nKey="subscriptions.toggleDisabled"
              values={{
                discount: discountPercentage,
              }}
            />
          )}
        </SubscriptionToggleWrapper>
      ) : subscriptionItem ? (
        <TimePassCopyWrapper>
          <Trans i18nKey="subscriptions.timePassCopy" />
        </TimePassCopyWrapper>
      ) : null}

      <PutItOnMyTabButton
        testId="put-it-on-your-tab"
        onClick={() => onPickOffering?.(selectedOffering)}
      >
        {isSubscriptions && subscriptionItem?.id === selectedOffering.id ? (
          <Trans
            i18nKey={
              isLoggedIn
                ? "subscriptions.subscribeAndSave"
                : "subscriptions.signUpAndSubscribe"
            }
          />
        ) : (
          <Trans i18nKey="putItOnMyTab" />
        )}
      </PutItOnMyTabButton>
    </>
  );
}
