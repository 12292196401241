import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Colors, TextSmall, TextTiny } from "../../../styles/GlobalStyles";

export const OutlinedInfo = styled.span`
  display: block;
  border: 1px solid ${Colors.lightblack};
  border-radius: 50%;
  width: 13px;
  height: 13px;
  font-size: 8px;
  font-weight: 700;
  margin-left: 4px;
`;

export const OptionalTabLink = styled.button`
  text-decoration: underline;
  cursor: pointer;
  margin-right: 2px;
`;

export const HelpWrapper = styled.div`
  position: relative;
  bottom: -25px;
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin-bottom: 11px;

  p {
    font-size: 13px;
    color: ${Colors.lightblack};
  }
`;

export const CopyWrapper = styled.div<{ isInfo: boolean }>`
  ${TextSmall}
  text-align: left;
  line-height: 21px;
  font-weight: 400;

  ${({ isInfo }) =>
    isInfo &&
    css`
      ${TextTiny}
      text-align: center;
      line-height: 20px;
      color: ${Colors.lightblack};
      margin: 3px 0 14px;
    `}
`;

export const TabCopyChildrenWrapper = styled.div<{ marginTop: boolean }>`
  margin-top: ${({ marginTop }) => (marginTop ? "15px" : "0")};
`;
