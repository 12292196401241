import { PaymentModel } from "@getsupertab/tapper-sdk";

export type {
  ClientConfig,
  CurrencyResponse,
  SiteOffering as ItemOffering,
  SubscriptionDetailsResponse as Subscription,
} from "@getsupertab/tapper-sdk";

/** Representation of a user's tab as returned from the API */
export type Tab = {
  id: string;
  merchantId: string;
  userId: string;
  status: TabStatus;
  createdAt: string;
  lastUpdatedAt: string;
  paidAt: string;
  limit: number;
  total: number;
  currency: string;
  paymentModel: PaymentModel;
  purchases: Purchase[];
  metadata?: {
    [x: string]: any;
  };
  tabStatistics: {
    obfuscatedPurchasesCount: number;
    obfuscatedPurchasesTotal: number;
    purchasesCount: number;
    purchasesNetAmount: number;
  };
  testMode: boolean;
};

export interface TabServerResponse {
  data: Tab[];
  metadata: {
    count: number;
    links: {
      previous: string | null;
      next: string | null;
    };
    number_pages: number;
    per_page: number;
  };
}

export enum TabStatus {
  Open = "open",
  Full = "full",
  Closed = "closed",
}

/** Representation of a user's purchase as part of a Tab */
export type Purchase = {
  merchantName: string;
  id: string;
  purchaseDate: string;
  merchantId: string;
  summary: string;
  price: {
    amount: number;
    currency: string;
  };
  salesModel: SalesModel;
  paymentModel: PaymentModel;
  metadata?: {
    [x: string]: any;
  };
  attributedTo: string;
  validTimedelta: string;
};

export enum SalesModel {
  Contribution = "contribution",
  TimePass = "time_pass",
  SinglePurchase = "single_purchase",
}

/** Representation of a user as returned from the API */
export type User = {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  active: boolean;
  defaultPaymentMethodSet: boolean;
  isEmailVerified: boolean;
  isSuperuser: boolean;
  createdAt: string;
};

/**
 * HTTP status code that indicates a successful response
 *
 * Invalid status codes produce an error.
 */
export type ValidStatusCode = 200 | 201 | 402;

export type ContentKeyDetail = {
  itemTemplateId: string;
  itemOfferingIds: string[];
  contentKey: string;
};

export enum StorageKeys {
  AccessToken = "lp_access_token",
  RefreshToken = "lp_refresh_token",
  AuthCode = "lp_auth_code",
  CodeVerifier = "lp_code_verifier",
}

export type ScreenHint = "register" | "login";
export type LoginAction = "login_click" | "login_widget_call" | "add_to_tab";

export type Merchant = {
  name: string;
  clientId: string;
};
