import { createMachine } from "xstate";
import * as guards from "./guards";
import * as services from "./services";
import * as actions from "./actions";
import * as delays from "./delays";
import { OnpageWidgetContext, OnpageWidgetEvent } from "./types";

export * from "./types";

const strict = window.location.search.includes("xstate-strict");

export default createMachine<OnpageWidgetContext, OnpageWidgetEvent>(
  {
    strict,
    predictableActionArguments: true,
    id: "onpageWidget",
    initial: "initializing",
    on: {
      API_ERROR: {
        actions: "sendError",
      },
      DONE_TAB_FETCH: {
        actions: "sendTab",
      },
      CHECK_ACCESS: [
        {
          cond: "shouldAlwaysGrantAccess",
          actions: ["assignAccessGranted", "dispatchBackToCaller"],
        },
        {
          cond: "isUserAuthorized",
          actions: "checkAccess",
        },
        {
          actions: ["assignAccessUnauthorized", "dispatchBackToCaller"],
        },
      ],
      DONE_ACCESS_CHECK: {
        actions: [
          "assignCheckAccessResult",
          "sendCheckAccessResult",
          "dispatchBackToCaller",
        ],
      },
      DONE_SUBSCRIPTION_FETCH: {
        actions: ["assignSubscription", "sendSubscription"],
      },
      REFRESHED_TOKENS: {
        actions: "sendTokens",
      },
    },
    states: {
      initializing: {
        tags: ["inactive"],
        initial: "initialize",
        states: {
          initialize: {
            entry: "spawnApiMachine",
            on: {
              DONE_CLIENT_CONFIG: {
                actions: ["assignClientConfig", "spawnAuthMachine"],
                target: "checkingIfShouldBypass",
              },
            },
          },
          checkingIfShouldBypass: {
            always: [
              {
                cond: "shouldAlwaysGrantAccess",
                actions: ["assignAccessGranted", "dispatchBackToCaller"],
                target: "#onpageWidget.inactive",
              },
              {
                cond: "isGtmEnabled",
                target: "loadingGtm",
              },
              {
                target: "#onpageWidget.checkingAccess",
              },
            ],
          },
          loadingGtm: {
            invoke: {
              id: "initializeGtm",
              src: "initializeGtm",
              onDone: "#onpageWidget.checkingAccess",
              onError: "#onpageWidget.checkingAccess",
            },
          },
        },
      },

      checkingAccess: {
        tags: ["inactive"],
        entry: "assignAccessChecking",
        initial: "checkingIfShouldBypass",
        states: {
          checkingIfShouldBypass: {
            always: [
              {
                cond: "shouldAlwaysDenyAccess",
                actions: ["assignAccessDenied", "dispatchBackToCaller"],
                target: "#onpageWidget.inactive",
              },
              {
                cond: "isUserAuthorized",
                target: "checkingAccess",
              },
              {
                target: "checkingAuth",
              },
            ],
          },
          checkingAuth: {
            entry: "checkAuth",
            on: {
              LOGGED_IN: [
                {
                  cond: "shouldSkipInitialAccessCheck",
                  actions: [
                    "assignUserAuthorizedStatus",
                    "spawnLeaveBehindMachine",
                    "sendLoggedIn",
                    "dispatchBackToCaller",
                  ],
                  target: "#onpageWidget.inactive",
                },
                {
                  target: "checkingAccess",
                  actions: [
                    "assignUserAuthorizedStatus",
                    "spawnLeaveBehindMachine",
                    "sendLoggedIn",
                  ],
                },
              ],
              AUTH_FAILED: {
                target: "#onpageWidget.inactive",
                actions: [
                  "assignAuthFailed",
                  "assignAccessUnauthorized",
                  "dispatchBackToCaller",
                  "sendAuthFailed",
                ],
              },
            },
          },
          checkingAccess: {
            initial: "checking",
            states: {
              checking: {
                entry: "checkAccess",
                on: {
                  DONE_ACCESS_CHECK: {
                    actions: [
                      "assignCheckAccessResult",
                      "spawnLeaveBehindMachine",
                      "sendCheckAccessResult",
                    ],
                    target: "backToCaller",
                  },
                  API_ERROR: {
                    actions: "assignError",
                    target: "#onpageWidget.inactive",
                  },
                },
              },
              backToCaller: {
                after: {
                  BACK_TO_CALLER_DELAY: [
                    {
                      cond: "hasItemAdded",
                      actions: "dispatchBackToCaller",
                      target: "#onpageWidget.inactive",
                    },
                    {
                      target: "#onpageWidget.fetchingTabAndPrices",
                    },
                  ],
                },
              },
            },
          },
        },
      },

      fetchingTabAndPrices: {
        tags: ["inactive"],
        initial: "fetchingTab",
        states: {
          fetchingTab: {
            entry: "fetchTab",
            on: {
              DONE_TAB_FETCH: [
                {
                  cond: "hasTabInDifferentCurrency",
                  actions: ["assignTab", "sendTab"],
                  target: "fetchingClientConfig",
                },
                {
                  actions: ["assignTab", "sendTab", "dispatchBackToCaller"],
                  target: "#onpageWidget.inactive",
                },
              ],
            },
          },
          fetchingClientConfig: {
            entry: "fetchClientConfig",
            on: {
              DONE_CLIENT_CONFIG: {
                actions: ["assignClientConfig", "dispatchBackToCaller"],
                target: "#onpageWidget.inactive",
              },
            },
          },
        },
      },

      inactive: {
        tags: ["inactive"],
        entry: ["removeSelectedOffering"],
        on: {
          CONTRIBUTE: [
            {
              cond: "isSimplifiedFlow",
              actions: "hideLeaveBehind",
              target: "buyingSimplified",
            },
            {
              actions: "hideLeaveBehind",
              target: "buying",
            },
          ],
          LOG_IN: {
            actions: "startAuth",
          },
          LOGGED_IN: {
            actions: [
              "assignUserAuthorizedStatus",
              "spawnLeaveBehindMachine",
              "sendLoggedIn",
            ],
            target: "#onpageWidget.checkingAccess",
          },
          LOG_OUT: {
            actions: "logOut",
          },
          LOGGED_OUT: {
            actions: ["assignUserAuthorizedStatus", "sendLoggedOut"],
          },
          DONE_TAB_FETCH: {
            actions: ["assignTab", "sendTab"],
          },
          DONE_PAYMENT: {
            actions: ["assignTabPaid", "sendTab"],
          },
          CHECK_ACCESS: {
            target: "checkingAccess",
          },
        },
      },

      buying: {
        tags: ["backdrop"],
        initial: "initial",
        on: {
          SHOW_TAB_DETAILS: {
            cond: "hasTab",
            target: "#onpageWidget.tabDetails",
          },
          SHOW_INFO: "#onpageWidget.info",
          CANCEL: {
            actions: ["cancelAuth", "resetIsSubscriptionEnabled"],
            target: "#onpageWidget.checkingAccess",
          },
        },
        states: {
          initial: {
            tags: ["inactive"],
            always: [
              {
                cond: "hasTab",
                actions: "removeItemAdded",
                target: "pickingOffering",
              },
              {
                cond: "isUserAuthorized",
                target: "fetchingTab",
              },
              {
                target: "pickingOffering",
              },
            ],
          },

          pickingOffering: {
            on: {
              PICK_OFFERING: [
                {
                  cond: "isUserAuthorizedAndIsSubscriptions",
                  target: "#onpageWidget.buyingNow",
                  actions: "assignOffering",
                },
                {
                  cond: "isUserAuthorized",
                  target: "processing",
                  actions: "assignOffering",
                },
                {
                  actions: ["assignOffering", "startAuth"],
                },
              ],
              LOG_IN: {
                actions: "startAuth",
              },
              LOGGED_IN: {
                actions: [
                  "assignUserAuthorizedStatus",
                  "spawnLeaveBehindMachine",
                  "sendLoggedIn",
                ],
                target: "checkingAccess",
              },
              AUTH_FAILED: {
                target: "pickingOffering",
                actions: "assignAuthFailed",
              },
              TOGGLE_SUBSCRIPTIONS: {
                actions: "assignIsSubscriptionEnabled",
              },
            },
          },

          fetchingTab: {
            tags: ["loading"],
            entry: "fetchTab",
            on: {
              DONE_TAB_FETCH: [
                {
                  cond: "isPaymentRequired",
                  actions: ["assignTab", "sendTab"],
                  target: "payment",
                },
                {
                  cond: "hasTabInDifferentCurrency",
                  actions: ["assignTab", "sendTab"],
                  target: "fetchingClientConfig",
                },
                {
                  cond: "hasSelectedOffering",
                  actions: ["assignTab", "sendTab"],
                  target: "processing",
                },
                {
                  actions: "assignClientConfig",
                  target: "pickingOffering",
                },
              ],
              API_ERROR: {
                actions: "assignError",
                target: "#onpageWidget.error",
              },
            },
          },

          fetchingSubscription: {
            tags: ["loading"],
            entry: "fetchSubscription",
            on: {
              DONE_SUBSCRIPTION_FETCH: {
                actions: "assignSubscription",
                target: "#onpageWidget.inactive",
              },
              API_ERROR: {
                actions: "assignApiError",
                target: "#onpageWidget.error",
              },
            },
          },

          fetchingClientConfig: {
            tags: ["loading"],
            entry: "fetchClientConfig",
            on: {
              DONE_CLIENT_CONFIG: [
                {
                  cond: "hasSelectedOffering",
                  actions: "assignClientConfig",
                  target: "processing",
                },
                {
                  actions: "assignClientConfig",
                  target: "pickingOffering",
                },
              ],
            },
          },

          checkingAccess: {
            tags: ["loading"],
            entry: ["assignAccessChecking", "checkAccess"],
            on: {
              DONE_ACCESS_CHECK: [
                {
                  cond: "hasAccess",
                  actions: [
                    "assignCheckAccessResult",
                    "sendCheckAccessResult",
                    "dispatchBackToCaller",
                  ],
                  target: "completedAfterLogin",
                },
                {
                  cond: "hasSubscription",
                  target: "fetchingSubscription",
                },
                {
                  target: "fetchingTab",
                },
              ],
              API_ERROR: {
                actions: ["assignError", "assignAccessError"],
                target: "#onpageWidget.error",
              },
            },
          },

          processing: {
            entry: "addToTab",
            // Disable cancelation or navigation in this state
            on: {
              CANCEL: "",
              SHOW_TAB_DETAILS: "",
              SHOW_INFO: "",
              DONE_TAB_ADD: [
                {
                  cond: "isPaymentRequired",
                  actions: "assignItemAdded",
                  target: "payment",
                },
                {
                  actions: ["assignItemAdded", "sendTab"],
                  target: "completed",
                },
              ],
              API_ERROR: {
                actions: ["assignError", "removeSelectedOffering"],
                target: "#onpageWidget.error",
              },
            },
          },

          completed: {
            tags: ["backdrop"],
          },

          completedAfterLogin: {
            tags: ["backdrop"],
          },

          payment: {
            tags: ["backdrop"],
            initial: "awaitingUserConfirmation",
            states: {
              awaitingUserConfirmation: {
                on: {
                  START_OFFPAGE_PAYMENT: "awaitingPaymentCompletion",
                  ACCEPT_BACKAWAY_OFFER: "acceptBackawayOffer",
                },
              },
              awaitingPaymentCompletion: {
                entry: "initPayment",
                on: {
                  START_OFFPAGE_PAYMENT: {
                    actions: "offpagePayment",
                    target: "awaitingPaymentCompletion",
                  },
                  DONE_PAYMENT: [
                    {
                      cond: "isSubscriptionOffering",
                      actions: ["assignTabPaid", "sendTab"],
                      target: "completed",
                    },
                    {
                      actions: ["assignTabPaid", "sendTab"],
                      target: "completed",
                    },
                  ],
                },
              },
              acceptBackawayOffer: {
                always: {
                  actions: "acceptBackawayOffer",
                  target: "#onpageWidget.inactive",
                },
              },

              completed: {
                tags: ["backdrop"],
              },
            },
          },

          history: {
            type: "history",
            history: "deep",
          },
        },
      },

      buyingNow: {
        tags: ["backdrop"],
        initial: "addPurchase",
        states: {
          addPurchase: {
            entry: ["assignCheckoutWindow", "addToTab"],
            on: {
              DONE_TAB_ADD: {
                cond: "isPaymentRequired",
                actions: "assignItemAdded",
                target: "#onpageWidget.buyingNow.payment",
              },
              API_ERROR: {
                actions: ["assignError", "removeSelectedOffering"],
                target: "#onpageWidget.error",
              },
            },
          },
          payment: {
            initial: "awaitingPaymentCompletion",
            states: {
              awaitingPaymentCompletion: {
                entry: "initPayment",
                on: {
                  PICK_OFFERING: [
                    {
                      cond: "isUserAuthorizedAndIsSubscriptions",
                      actions: ["assignOffering", "initPayment"],
                    },
                    {
                      actions: ["resetIsSubscriptionEnabled", "assignOffering"],
                      target: "#onpageWidget.buying.processing",
                    },
                  ],
                  DONE_PAYMENT: {
                    actions: [
                      "assignTabPaid",
                      "sendTab",
                      "unAssignCheckoutWindow",
                    ],
                    target: "#onpageWidget.checkingAccess",
                  },
                },
              },
            },
          },
        },
      },

      buyingSimplified: {
        initial: "initial",
        states: {
          initial: {
            always: [
              {
                cond: "hasTab",
                actions: "removeItemAdded",
                target: "addToTab",
              },
              {
                cond: "isUserAuthorized",
                target: "addToTab",
              },
              {
                target: "startAuth",
              },
            ],
          },

          startAuth: {
            tags: ["backdrop"],
            initial: "waiting",
            states: {
              waiting: {
                after: {
                  500: "openAuth",
                },
              },
              openAuth: {
                entry: "startAuth",
                on: {
                  LOGGED_IN: {
                    actions: [
                      "assignUserAuthorizedStatus",
                      "spawnLeaveBehindMachine",
                      "sendLoggedIn",
                    ],
                    target: "#onpageWidget.buyingSimplified.addToTab",
                  },
                  SIGN_UP: {
                    actions: "startAuth",
                  },
                  AUTH_FAILED: {
                    actions: [
                      "assignAuthFailed",
                      "assignAccessUnauthorized",
                      "dispatchBackToCaller",
                      "sendAuthFailed",
                    ],
                    target: "#onpageWidget.inactive",
                  },
                },
              },
            },
          },

          addToTab: {
            tags: ["inactive"],
            initial: "checkingAccess",
            states: {
              checkingAccess: {
                entry: "checkAccess",
                on: {
                  DONE_ACCESS_CHECK: [
                    {
                      cond: "hasAccess",
                      actions: [
                        "assignCheckAccessResult",
                        "sendCheckAccessResult",
                        "dispatchBackToCaller",
                      ],
                      target: "#onpageWidget.inactive",
                    },
                    {
                      target: "fetchingTabAndPrices",
                    },
                  ],
                },
              },
              fetchingTabAndPrices: {
                tags: ["inactive"],
                initial: "fetchingTab",
                states: {
                  fetchingTab: {
                    entry: "fetchTab",
                    on: {
                      DONE_TAB_FETCH: [
                        {
                          cond: "hasTabInDifferentCurrency",
                          actions: ["assignTab", "sendTab"],
                          target: "fetchingClientConfig",
                        },
                        {
                          actions: ["assignTab", "sendTab"],
                          target:
                            "#onpageWidget.buyingSimplified.addToTab.addingToTab",
                        },
                      ],
                    },
                  },
                  fetchingClientConfig: {
                    entry: "fetchClientConfig",
                    on: {
                      DONE_CLIENT_CONFIG: {
                        actions: "assignClientConfig",
                        target:
                          "#onpageWidget.buyingSimplified.addToTab.addingToTab",
                      },
                    },
                  },
                },
              },
              addingToTab: {
                entry: ["assignCheapestOffering", "addToTab"],
                on: {
                  DONE_TAB_ADD: [
                    {
                      cond: "isPaymentRequired",
                      actions: "assignItemAdded",
                      target: "#onpageWidget.buyingSimplified.payment",
                    },
                    {
                      actions: ["assignItemAdded", "sendTab"],
                      target: "#onpageWidget.checkingAccess",
                    },
                  ],
                },
              },
            },
          },

          payment: {
            tags: ["backdrop"],
            initial: "initial",
            states: {
              initial: {
                after: {
                  SIMPLIFIED_FLOW_INITIATE_PAYMENT_DELAY:
                    "awaitingPaymentCompletion",
                },
              },
              awaitingPaymentCompletion: {
                entry: "initPayment",
                on: {
                  START_OFFPAGE_PAYMENT: {
                    actions: "initPayment",
                  },
                  DONE_PAYMENT: {
                    actions: ["assignTabPaid", "sendTab"],
                    target: "#onpageWidget.checkingAccess",
                  },
                },
              },
            },
          },
        },
      },

      info: {
        tags: ["backdrop"],
        on: {
          BACK: "buying.history",
        },
      },

      tabDetails: {
        tags: ["backdrop"],
        on: {
          BACK: "buying.history",
        },
      },

      error: {
        tags: ["backdrop"],
        entry: "trackError",
        on: {
          BACK: "#onpageWidget.inactive",
        },
      },
    },
  },
  {
    services,
    guards,
    actions,
    delays,
  }
);
