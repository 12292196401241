import { timestampInMilliseconds } from "../../util/misc";
import { UserAccessStatus } from "../onpageWidget";
import { LeaveBehindMachineContext, LeaveBehindMachineEvents } from "./types";

export function hasApiError(ctx: LeaveBehindMachineContext) {
  return !!ctx.apiError?.message;
}

export function hasSubscription(ctx: LeaveBehindMachineContext) {
  return !!ctx.userAccessDetails[0]?.subscriptionId;
}

export function doesNotHaveTab(ctx: LeaveBehindMachineContext) {
  return !!ctx.userAuthorized && !ctx.tab;
}

export function shouldShowThankYou(
  ctx: LeaveBehindMachineContext,
  ev: LeaveBehindMachineEvents
) {
  return (
    ev.type === "DONE_ACCESS_CHECK" &&
    !!ev.data.itemAdded &&
    ctx.userAccessStatus !== UserAccessStatus.CHECKING &&
    ev.data.userAccessStatus === UserAccessStatus.ACCESS_GRANTED &&
    ctx.userAccessDetails !== ev.data.userAccessDetails
  );
}

export function isAccessFinished(ctx: LeaveBehindMachineContext) {
  return (
    ctx.userAccessDetails[0] !== undefined &&
    timestampInMilliseconds(ctx.userAccessDetails[0].validTo) < Date.now()
  );
}
